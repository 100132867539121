import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../components/firebase'; // Make sure this path is correct
import room1 from '../assets/standard.jpg';
import room2 from '../assets/twin1.jpg';
import room3 from '../assets/semi1.jpg';
import room4 from '../assets/sweet1.jpg';
import 'aos/dist/aos.css';
import AOS from 'aos';


const initialRooms = [
  {
    id: 1,
    name: "Standard Room",
    image: room1,
    price: "1500 / Night",
    description: "Our Standard Rooms are comfortable and well-equipped for a pleasant stay. They feature a cozy bed, modern amenities, and a serene ambiance.",
    characteristics: [
      { icon: "👥", label: "2 Persons" },
      { icon: "📶", label: "Free Wifi" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🍽️", label: "Breakfast Included" },
      { icon: "🛁", label: "Towels" },
      { icon: "📺", label: "TV" },
      { icon: "🪑", label: "Table and Chair" },
      { icon: "🏞️", label: "Patio" },
    ],
    availableRooms: 0, // Placeholder for Firestore data
    firestoreId: "Standard"
  },
  {
    id: 2,
    name: "TwinBed Room",
    image: room2,
    price: "1800 / Night",
    description: "Our Twin Bed Rooms are spacious and perfect for two guests. They offer twin beds, a comfortable seating area, and beautiful views.",
    characteristics: [
      { icon: "👥", label: "2-4 Persons" },
      { icon: "🛏️", label: "Twin Beds" },
      { icon: "📶", label: "Free Wifi" },
      { icon: "🍽️", label: "Breakfast Included" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🛁", label: "Towels" },
      { icon: "📺", label: "TV" },
      { icon: "🪑", label: "Table and Chair" },
      { icon: "🏞️", label: "Patio" },
    ],
    availableRooms: 0, // Placeholder for Firestore data
    firestoreId: "room2"
  },
  {
    id: 3,
    name: "Semi-Suite Room",
    image: room3,
    price: "1600 / Night",
    description: "Our Semi-Suite Rooms offer extra space and comfort for your stay. They include a cooking space for your convenience, along with all essential amenities.",
    characteristics: [
      { icon: "👥", label: "1-2 Persons" },
      { icon: "📶", label: "Free Wifi" },
      { icon: "🍽️", label: "Breakfast Included" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🍳", label: "Cooking Space" },
      { icon: "🛁", label: "Towels" },
      { icon: "📺", label: "TV" },
      { icon: "🪑", label: "Table and Chair" },
      { icon: "🏞️", label: "Patio" },
    ],
    availableRooms: 0, // Placeholder for Firestore data
    firestoreId: "room3"
  },
  {
    id: 4,
    name: "Suite Room",
    image: room4,
    price: "3000 / Night",
    description: "Our Suite Rooms provide luxury and comfort for a premium stay. They feature a separate salon, bedroom, two bathrooms, air conditioning, and a fully equipped kitchen.",
    characteristics: [
      { icon: "👥", label: "1-2 Persons" },
      { icon: "📶", label: "Free Wifi" },
      { icon: "🍽️", label: "Breakfast Included" },
      { icon: "🛋️", label: "Separate Salon" },
      { icon: "🛌", label: "Separate Bedroom" },
      { icon: "🚽", label: "Two Bathrooms" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🍳", label: "Full Kitchen" },
      { icon: "🛁", label: "Towels" },
      { icon: "📺", label: "TV" },
      { icon: "🪑", label: "Table and Chair" },
      { icon: "🏞️", label: "Patio" },
    ],
    availableRooms: 0, // Placeholder for Firestore data
    firestoreId: "room4"
  },
];

const Rooms = () => {

  const [rooms, setRooms] = useState(initialRooms);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchRoomData = async () => {
      const updatedRooms = await Promise.all(
        initialRooms.map(async (room) => {
          const docRef = doc(db, 'rooms', room.firestoreId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const roomData = docSnap.data();
            return { ...room, availableRooms: roomData.avaliableRooms };
          } else {
            console.log(`No such document for ${room.name}`);
            return room;
          }
        })
      );
      setRooms(updatedRooms);
    };

    fetchRoomData();
  }, []);






  return (
    <div className="bg-gray-100">
      <header className="bg-white py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-3xl font-blod text-custom-blue font-times">Rooms and Suites</h1>
        </div>
      </header>
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {rooms.map((room, index) => (
            <div
              key={room.id}
              className={`relative mb-16 flex flex-col md:flex-row ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center`}
              data-aos={`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`}
            >
              <img
                src={room.image}
                alt={room.name}
                className="h-112 object-cover w-full md:w-3/5"
              />
              <div
                className="bg-white p-6 md:p-12 shadow-md w-full md:w-2/5 md:relative"
                style={{
                  top: '10%',
                  left: index % 2 === 0 ? 'auto' : '5%',
                  right: index % 2 === 0 ? '5%' : 'auto',
                  transform: 'translateY(0%)',
                }}
              >
                <p className="text-lg font-bold text-custom-blue mb-2 font-times">{room.price}</p>
                <h3 className="text-2xl text-custom-blue md:text-3xl mb-2 font-times">{room.name}</h3>
                <p className="text-custom-blue mb-4 font-times">{room.description}</p>
               
                <div className="flex flex-wrap items-center space-x-4 mb-4">
                  {room.characteristics.map((characteristic, idx) => (
                    <div key={idx} className="flex items-center text-custom-blue space-x-2 mb-2 font-times">
                      <span role="img" aria-label="characteristic-icon">{characteristic.icon}</span>
                      <span>{characteristic.label}</span>
                    </div>
                  ))}
                </div>
                <Link to={`/rooms/${room.id}`} className="text-custom-blue font-times hover:underline">
                  View → Detail
                </Link>
      
              </div>
            </div>
          ))}
        </div>
      </section>

    </div>
  );
};

export default Rooms;
