import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import room1 from '../assets/sweet1.jpg';
import room2 from '../assets/semi1.jpg';
import room3 from '../assets/twin1.jpg';
import restaurant from '../assets/res.jpg';
import hotelImage from '../assets/Home.jpg'; 
import special from '../assets/combo1.jpg'
import pizza from'../assets/pizza.jpg'
import meeting from '../assets/m1.jpg';
import meeting2 from '../assets/m2.jpg';
import chicken from '../assets/chicken.jpg'
import club from '../assets/club.jpg';
import { FaStar } from 'react-icons/fa'; 
import room4 from '../assets/standard.jpg'
import RoomSearch from '../components/roomserarch';


const Home = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [meeting, meeting2]; 
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => {
      clearInterval(interval); 
    };
  }, [images.length])


  

  // const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // const togglePlayPause = () => {
  //   if (isPlaying) {
  //     videoRef.current.pause();
  //   } else {
  //     videoRef.current.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };

  const luxuryCollectionsRef = useRef(null);
  const [luxuryCollectionsInView, setLuxuryCollectionsInView] = useState(false);

  // Intersection Observer to detect if the section is in view
  useEffect(() => {
    const handleScroll = () => {
      if (luxuryCollectionsRef.current) {
        const rect = luxuryCollectionsRef.current.getBoundingClientRect();
        setLuxuryCollectionsInView(rect.top < window.innerHeight && rect.bottom > 0);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const { ref: headerRef, inView: headerInView } = useInView({ triggerOnce: true });
  const { ref: roomsRef, inView: roomsInView } = useInView({ triggerOnce: true });
  const { ref: foodRef, inView: foodInView } = useInView({ triggerOnce: true });
  const { ref: meetingsRef, inView: meetingsInView } = useInView({ triggerOnce: true });
  const { ref: gettingHereRef, inView: gettingHereInView } = useInView({ triggerOnce: true });

  return (
    <main className="bg-gray-100">
      {isDesktopOrLaptop ? (
   <div className="relative">
   <img 
     src={hotelImage} 
     alt="Hotel" 
     className="w-full object-cover h-[600px]" // Use Tailwind's h-[600px] for height
   />
   <div className="absolute inset-0 0"></div>
   <div className="absolute inset-0 flex flex-col justify-center items-center p-4 sm:p-6">
     <RoomSearch />
   </div>
 </div>

      ) : (
        <div className="relative">
        <img 
          src={hotelImage} 
          alt="Hotel" 
          className="w-full object-cover h-[600px]" // Use Tailwind's h-[600px] for height
        />
        <div className="absolute inset-0"></div>
        <div className="absolute inset-0 flex flex-col justify-center items-center p-4 sm:p-6">
          <RoomSearch />
        </div>
      </div>
      )}
      <header className="bg-white py-8" ref={headerRef}>
        <motion.div
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <h1 className="text-3xl text-custom-blue font-bold font-times">Welcome to Caravan Dire Hotel</h1>
          <div className="flex items-center justify-center mt-2">
            <p className="text-xl text-gray-600 font-times flex">
              <FaStar className="text-yellow-500 mr-1" />
              <FaStar className="text-yellow-500 mr-1" />
              <FaStar className="text-yellow-500 mr-1" />
              <FaStar className="text-yellow-500" />
            </p>
          </div>
        </motion.div>
      </header>

      <section className="bg-white py-16">
        <motion.div
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          initial={{ opacity: 0, y: 50 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <h2 className="text-2xl text-gray-900 font-times">Experience Comfort and Elegance in Dire Dawa, Ethiopia</h2>
          <p className="mt-4 text-gray-600 font-times">
            Welcome to Caravan Dire Hotel, the premier choice for luxury and comfort in Dire Dawa, Ethiopia. Enjoy exceptional services, delicious dining, and modern amenities, including high-speed WiFi and 24/7 security. Whether you're visiting for business or leisure, our attentive staff ensures a memorable stay. Discover competitive Dire Dawa hotel prices and easy booking options. For inquiries, call our Dire Dawa hotel phone number. Experience the best at Caravan Dire Hotel, your top destination in Dire Dawa.
          </p>
          <br /><br />
          <Link to="/about">
            <button className="bg-custom-blue font-times text-white text-lg px-4 py-2 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 w-48">
              More About-us
            </button>
          </Link>
        </motion.div>
      </section>
      <section className="py-16 bg-white" ref={roomsRef}>
  <motion.div
    className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
    initial={{ opacity: 0, y: 50 }}
    animate={roomsInView ? { opacity: 1, y: 0 } : {}}
    transition={{ duration: 1 }}
  >
    <h2 className="text-xlmb-8  text-custom-blue text-center font-times">Rooms and Suites</h2>
    <h2 className="text-4xl mb-8  text-custom-blue text-center font-times">Discover Our Rooms</h2>
    <div className="overflow-x-auto">
      <div className="flex space-x-8">
        <motion.div
          className="bg-white shadow-lg rounded-lg overflow-hidden flex-shrink-0 w-64"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.img
            src={room1}
            alt="Suite Room"
            className="w-full h-64 object-cover"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          />
          <div className="p-6">
            <h3 className="text-xl text-custom-blue font-times">Suite Room</h3>
          </div>
        </motion.div>
        <motion.div
          className="bg-white shadow-lg rounded-lg overflow-hidden flex-shrink-0 w-64"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.img
            src={room2}
            alt="Semi-Suite Room"
            className="w-full h-64 object-cover"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          />
          <div className="p-6">
            <h3 className="text-xl  text-custom-blue font-times">Semi-Suite Room</h3>
          </div>
        </motion.div>
        <motion.div
          className="bg-white shadow-lg rounded-lg overflow-hidden flex-shrink-0 w-64"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.img
            src={room3}
            alt="Twin Bed Room"
            className="w-full h-64 object-cover"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          />
          <div className="p-6">
            <h3 className="text-xl text-custom-blue font-times">Twin Bed Room</h3>
          </div>
        </motion.div>
        <motion.div
          className="bg-white shadow-lg rounded-lg overflow-hidden flex-shrink-0 w-64"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.img
            src={room4}
            alt="Twin Bed Room"
            className="w-full h-64 object-cover"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          />
          <div className="p-6">
            <h3 className="text-xl text-custom-blue font-times">Standard Room</h3>
          </div>
        </motion.div>
      </div>
    </div>
    <div className="flex justify-center mt-8">
      <Link to="/rooms">
        <button className="bg-custom-blue font-times text-white text-lg px-4 py-2 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 w-48">
          View more
        </button>
      </Link>
    </div>
  </motion.div>
</section>

    <section className="py-16 bg-white" ref={foodRef}>
  <motion.div
    className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
    initial={{ opacity: 0, y: 50 }}
    animate={foodInView ? { opacity: 1, y: 0 } : {}}
    transition={{ duration: 1 }}
  >
    <h2 className="text-3xl font-times text-custom-blue text-center mb-8">Food and Beverage</h2>
    <div className="mt-8 bg-white shadow-md rounded-lg overflow-hidden flex flex-col md:flex-row">
      <div className="md:w-1/2">
        <img src={restaurant} alt="Restaurant" className="w-full h-full object-cover"/>
      </div>
      <div className="md:w-1/2 p-6 flex flex-col justify-center">
        <p className="text-gray-600 text-lg font-times mb-4">
          Experience our restaurant with a variety of delicious dishes and beverages. From local specialties to international cuisines, our chefs craft each dish with care using fresh, seasonal ingredients to ensure a delightful dining experience for every guest.
        </p>
        <Link to="/restaurant">
          <button className="bg-custom-blue text-white font-times text-lg px-4 py-2 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 w-48">Discover More</button>
        </Link>
      </div>
    </div>
  </motion.div>
</section>

<section className="py-16 bg-white">
  <motion.div
    className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
    initial={{ opacity: 0, y: 50 }}
    animate={foodInView ? { opacity: 1, y: 0 } : {}}
    transition={{ duration: 1 }}
  >
    <h2 className="text-3xl font-times text-custom-blue text-center mb-8">Our Well-Known Foods</h2>
    <div className="overflow-x-auto">
      <div className="flex space-x-8 min-w-max"> {/* Increased space between items */}
        <motion.div className="bg-white shadow-md rounded-lg overflow-hidden flex-shrink-0 w-64">
          <img src={special} alt="Special Combo" className="w-full h-64 object-cover"/>
          <div className="p-6">
            <h3 className="text-xl text-custom-blue font-times">Special Combo</h3>
            <p className="text-gray-600 mt-2 font-times">Special Combo - is a combination of traditional Ethiopian foods with rice.</p>
          </div>
        </motion.div>
        <motion.div className="bg-white shadow-md rounded-lg overflow-hidden flex-shrink-0 w-64">
          <img src={chicken} alt="Chicken Mandi" className="w-full h-64 object-cover"/>
          <div className="p-6">
            <h3 className="text-xl text-custom-blue font-times">Chicken Mandi</h3>
            <p className="text-gray-600 mt-2 font-times">Chicken Mandi - is a delicious middle-eastern chicken and rice dish with ...</p>
          </div>
        </motion.div>
        <motion.div className="bg-white shadow-md rounded-lg overflow-hidden flex-shrink-0 w-64">
          <img src={pizza} alt="Pizza" className="w-full h-64 object-cover"/>
          <div className="p-6">
            <h3 className="text-xl text-custom-blue font-times">Pizza</h3>
            <p className="text-gray-600 mt-2 font-times">Pizza - is a popular Italian dish with various toppings and melted cheese.</p>
          </div>
        </motion.div>
        <motion.div className="bg-white shadow-md rounded-lg overflow-hidden flex-shrink-0 w-64">
          <img src={club} alt="Pizza" className="w-full h-64 object-cover"/>
          <div className="p-6">
            <h3 className="text-xl text-custom-blue font-times">Club Sandwich</h3>
            <p className="text-gray-600 mt-2 font-times">Club Sandwich - is a classic sandwich made with layers of chicken, tuna,lettuce, and tomato.</p>
          </div>
        </motion.div>
      </div>
    </div>
    <div className="flex justify-center mt-12"> {/* Increased margin above the link */}
      <Link to="/menu" className="bg-custom-blue text-white text-lg px-6 py-3 font-times rounded-full shadow-lg hover:bg-blue-700 transition duration-300 w-48 text-center">
        View menu
      </Link>
    </div>
  </motion.div>
</section>


      
      {/* <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-h-96 overflow-hidden flex justify-center items-center">
            <div className="relative sm:w-120 md:w-144 lg:w-160 h-auto sm:h-96 md:h-120 lg:h-144">
              <video ref={videoRef} className="w-full h-full" controls={false}>
                <source src={promotion} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button
                onClick={togglePlayPause}
                className="absolute inset-0 flex items-center justify-center text-white text-4xl"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
              >
                {isPlaying ? '❚❚' : '►'}
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <section className="relative py-16 bg-white" ref={luxuryCollectionsRef}>
  <div className="absolute inset-x-0 top-0 h-24 bg-gradient-to-b from-custom-blue to-transparent -z-10"></div>
  <motion.div
    className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
    initial={{ opacity: 0, y: 50 }}
    animate={luxuryCollectionsInView ? { opacity: 1, y: 0 } : {}}
    transition={{ duration: 1 }}
  >
    <div className="text-center">
      <div className="bg-white shadow-lg  overflow-hidden relative z-10">
        <img src={room1} alt="Luxury Collections" className="w-full h-96 object-cover" />
        <div className="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-50">
          <div className="text-center p-4">
            <h2 className="text-3xl font-times mb-4">Discover Our Luxury Collections</h2>
            <p className="text-lg  font-times mb-4">Experience our exquisite range of services designed for your comfort and convenience.</p>
          </div>
        </div>
      </div>
    </div>



    <div className="text-center mt-12">
      <Link to="/services">
        <button className="bg-custom-blue font-times text-white text-lg px-4 py-2 rounded-full shadow-lg hover:bg-blue-700 transition duration-300">
          Discover Services
        </button>
      </Link>
    </div>
  </motion.div>
</section>

      <section className="py-16 bg-white" ref={meetingsRef}>
        <motion.div
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          initial={{ opacity: 0, y: 50 }}
          animate={meetingsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <h2 className="text-4xl font-times text-custom-blue text-center mb-8">Meetings and Events</h2>
          <div className="mt-8 bg-white shadow-md rounded-lg overflow-hidden flex flex-col-reverse md:flex-row items-center">
            <div className="p-4 md:w-1/2 text-center md:text-left">
              <p className="text-gray-600 text-start font-times">
                Our spacious meeting hall is equipped with modern facilities to accommodate various events. Whether you're hosting a corporate meeting, seminar, or social gathering, we offer flexible setups, audiovisual equipment, and catering services. The hall can accommodate up to 361 people, with options for tea breaks and lunch buffets tailored to your event's needs.
              </p>
              <Link to="/meeting" className="bg-custom-blue font-times text-white text-lg px-4 py-2 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 mt-4 block max-w-60 mx-auto md:mx-0">
                See Detail
              </Link>
            </div>
            <motion.img
              src={images[currentImageIndex]}
              alt="Meetings and Events"
              className="w-full md:w-1/2 h-auto object-cover"
              initial={{ opacity: 0, x: -50 }}
              animate={meetingsInView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 1 }}
            />
          </div>
        </motion.div>
      </section>

      <section className="py-16" ref={gettingHereRef}>
        <motion.div
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          initial={{ opacity: 0, y: 50 }}
          animate={gettingHereInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <h2 className="text-2xl font-times text-custom-blue">Getting Here</h2>
          <div className="mt-8 bg-white shadow-md rounded-lg overflow-hidden" style={{ height: '500px' }}>
            <iframe
              className="w-full h-full rounded"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6812.753183884451!2d41.8515825!3d9.5969225!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x163101bb5da204fd%3A0x59b3f43b43a14f0e!2sCaravan%20Hotel!5e1!3m2!1sam!2set!4v1719844803749!5m2!1sam!2set"
              allowFullScreen=""
              loading="lazy"
              title="Hotel Location"
            ></iframe>
          </div>
        </motion.div>
      </section>
    </main>
  );
}

export default Home;
