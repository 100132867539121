import React, { useState, useEffect } from 'react';
import { db } from '../components/firebase';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/spinner';

const RoomSearch = () => {
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [loading, setLoading] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const getNextDayDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    if (checkInDate) {
      const nextDay = new Date(checkInDate);
      nextDay.setDate(nextDay.getDate() + 1);
      const yyyy = nextDay.getFullYear();
      const mm = String(nextDay.getMonth() + 1).padStart(2, '0');
      const dd = String(nextDay.getDate()).padStart(2, '0');
      setCheckOutDate(`${yyyy}-${mm}-${dd}`);
    }
  }, [checkInDate]);


  

  const handleSearch = async () => {
  setLoading(true);
  setError('');

  if (!checkInDate || !checkOutDate) {
    setError('Please select both check-in and check-out dates.');
    setLoading(false);
    return;
  }

  try {
    const checkInTimestamp = Timestamp.fromDate(new Date(checkInDate));
    const checkOutTimestamp = Timestamp.fromDate(new Date(checkOutDate));

    // Calculate the current time and the threshold time (4 hours ago)
    const now = new Date();
    const thresholdTime = new Date(now.getTime() - 4 * 60 * 60 * 1000); // 4 hours in milliseconds

    // Query both bookings and reservations collections
    const bookingQuery = query(
      collection(db, 'bookings'),
      where('checkIn', '<=', checkOutTimestamp),
      where('checkOut', '>=', checkInTimestamp)
    );
    const reservationQuery = query(
      collection(db, 'reservations'),
      where('checkIn', '<=', checkOutTimestamp),
      where('checkOut', '>=', checkInTimestamp)
    );

    const [bookingSnapshot, reservationSnapshot] = await Promise.all([
      getDocs(bookingQuery),
      getDocs(reservationQuery)
    ]);

    const bookings = bookingSnapshot.docs.map(doc => doc.data());
    const reservations = reservationSnapshot.docs.map(doc => doc.data());

    // Filter out reservations that are older than 4 hours
    const filteredReservations = reservations.filter(reservation => {
      const reservationTimestamp = reservation.timestamp.toDate();
      return reservationTimestamp > thresholdTime;
    });

    // Combine bookings and filtered reservations
    const allBookings = [...bookings, ...filteredReservations];

    // Reduce function to calculate booked rooms
    const bookedRoomsCount = allBookings.reduce((acc, booking) => {
      // Check if it's a booking or reservation by the presence of `rooms`
      if (booking.rooms) {
        booking.rooms.forEach(room => {
          if (!acc[room.type]) {
            acc[room.type] = 0;
          }
          acc[room.type] += room.quantity;
        });
      } else if (booking.roomType) {
        // Handle reservation structure
        if (!acc[booking.roomType]) {
          acc[booking.roomType] = 0;
        }
        acc[booking.roomType] += 1; // Assuming each reservation is for one room
      }
      return acc;
    }, {});

    // Define total room quantities
    const totalRoomQuantities = {
      "Standard Room": 20,
      "TwinBed Room": 8,
      "Semi-Suite Room": 8,
      "Suite Room": 4
    };

    // Calculate available rooms
    const availableRooms = Object.keys(totalRoomQuantities).map(roomType => ({
      type: roomType,
      total: totalRoomQuantities[roomType],
      booked: bookedRoomsCount[roomType] || 0,
      available: totalRoomQuantities[roomType] - (bookedRoomsCount[roomType] || 0)
    }));

    // Filter rooms with availability
    const roomsWithAvailability = availableRooms.filter(room => room.available > 0);

    if (roomsWithAvailability.length === 0) {
      setError('No available rooms found for the selected dates.');
      setAvailableRooms([]);
    } else {
      setAvailableRooms(roomsWithAvailability);
      navigate('/Available', {
        state: { checkInDate, checkOutDate, adults, children, rooms: roomsWithAvailability }
      });
    }
  } catch (error) {
    console.error("Error fetching rooms: ", error);
    setError('Failed to fetch room availability. Please try again.');
  } finally {
    setLoading(false);
  }
};

  const inputStyle = {
    border: '2px solid #0056b3',
    padding: '0.5rem 1rem',
    borderRadius: '0.375rem',
    fontFamily: 'Times New Roman, serif',
    width: '100%',
    maxWidth: '300px',
    height: '40px'
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {loading && <Spinner />}
      <div className="relative max-w-5xl mx-auto p-4 sm:p-6 bg-white bg-opacity-50 rounded-md shadow-lg">
        <motion.div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl lg:text-4xl text-custom-blue font-bold text-start font-times">Experience our luxury Hotel</h2>


          <div className="flex flex-col items-center md:flex-row md:justify-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex flex-col space-y-2 w-full md:w-1/2 lg:w-1/3">
              <label className="font-times text-gray-700">Check-in Date:</label>
              <input
                type="date"
                value={checkInDate}
                onChange={(e) => setCheckInDate(e.target.value)}
                min={getTodayDate()}
                style={inputStyle}
              />
            </div>
            <div className="flex flex-col space-y-2 w-full md:w-1/2 lg:w-1/3">
              <label className="font-times text-gray-700">Check-out Date:</label>
              <input
                type="date"
                value={checkOutDate}
                onChange={(e) => setCheckOutDate(e.target.value)}
                min={checkInDate ? checkInDate : getNextDayDate()}
                style={inputStyle}
              />
            </div>
            <div className="flex flex-col space-y-2 w-full md:w-1/2 lg:w-1/3">
              <label className="font-times text-gray-700">Adults:</label>
              <input
                type="number"
                min="1"
                value={adults}
                onChange={(e) => setAdults(Number(e.target.value))}
                style={inputStyle}
                placeholder="Adults"
              />
            </div>
            <div className="flex flex-col space-y-2 w-full md:w-1/2 lg:w-1/3">
              <label className="font-times text-gray-700">Children:</label>
              <input
                type="number"
                min="0"
                value={children}
                onChange={(e) => setChildren(Number(e.target.value))}
                style={inputStyle}
                placeholder="Children"
              />
            </div>
            <div className="flex flex-col items-center md:items-start w-full md:w-auto md:ml-4">
              <label className="font-times text-gray-700">Click Here</label>
              <button
                onClick={handleSearch}
                className="bg-custom-blue text-white px-4 py-2 rounded-md border-2 border-custom-blue font-times"
                style={{ width: '100%', maxWidth: '300px', height: '40px' }}
              >
                Search
              </button>
            </div>
          </div>
          {error && (
            <div className="text-red-500 text-center mt-4">
              <p>{error}</p>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default RoomSearch;
