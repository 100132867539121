import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StandardImg from '../assets/standard.jpg';
import TwinImg from '../assets/twin1.jpg';
import SemiSweetImg from '../assets/semi1.jpg';
import SweetImg from '../assets/sweet.jpg';

const AvailableRoomsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { checkInDate, checkOutDate, adults, children, rooms } = location.state || {};

  const images = {
    "Standard Room": StandardImg,
    "TwinBed Room": TwinImg,
    "Semi-Suite Room": SemiSweetImg,
    "Suite Room": SweetImg,
  };


  const availableRooms = rooms?.filter(room => room.available > 0) || [];

  const getRoomNumber = (type) => {
    switch(type) {
      case 'Standard Room':
        return 1;
      case 'TwinBed Room':
        return 2;
      case 'Semi-Suite Room':
        return 3;
      case 'Suite Room':
        return 4;
      default:
        return 1;
    }
  };

  const handleViewDetails = (roomType) => {
    navigate(`/rooms/${getRoomNumber(roomType)}`, {
      state: { checkInDate, checkOutDate, children, adults }
    });
  };

  return (
    <div className="bg-white">
      <header className="bg-custom-blue py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-5xl font-bold text-white font-times mb-8">
            Search Results
          </h1>
          {checkInDate && checkOutDate && (
            <div className="flex flex-col items-center space-y-4 text-white">
              <div className="flex items-center">
                <span className="font-times text-lg font-semibold mr-2">Check-in Date:</span>
                <span className="font-times text-lg">{checkInDate}</span>
              </div>
              <div className="flex items-center">
                <span className="font-times text-lg font-semibold mr-2">Check-out Date:</span>
                <span className="font-times text-lg">{checkOutDate}</span>
              </div>
              <div className="flex items-center">
                <span className="font-times text-lg font-semibold mr-2">Adults:</span>
                <span className="font-times text-lg">{adults}</span>
              </div>
              <div className="flex items-center">
                <span className="font-times text-lg font-semibold mr-2">Children:</span>
                <span className="font-times text-lg">{children}</span>
              </div>
            </div>
          )}
        </div>
      </header>

      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {availableRooms.length > 0 ? (
            availableRooms.map((room, index) => (
              <div
                key={room.type}
                className={`relative mb-16 flex flex-col md:flex-row ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center`}
                data-aos={`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`}
              >
                <div className="relative w-full md:w-3/5">
                  {images[room.type] ? (
                    <img
                      src={images[room.type]}
                      alt={room.type}
                      className="h-112 object-cover w-full"
                    />
                  ) : (
                    <p className="text-red-500">Image not found for {room.type}</p>
                  )}
                  <div className="absolute inset-0 bg-blue-500 opacity-30"></div>
                </div>
                <div
                  className="bg-white p-6 md:p-12 shadow-md w-full md:w-2/5 md:relative"
                  style={{
                    top: '10%',
                    left: index % 2 === 0 ? 'auto' : '5%',
                    right: index % 2 === 0 ? '5%' : 'auto',
                    transform: 'translateY(0%)',
                  }}
                >
                  <h3 className="text-xl text-custom-blue md:text-3xl mb-2 font-times">{room.type}</h3>
                  <h3 className="text-md text-custom-blue md:text-3xl mb-2 font-times">Available rooms: {room.available}</h3>
                  <button 
                    onClick={() => handleViewDetails(room.type)} 
                    className="text-custom-blue font-times hover:underline"
                  >
                    View Details →
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-600 font-times">No rooms available at the moment.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default AvailableRoomsPage;
